import { MaxLength } from '@/lib/const';
import { validateValue } from '@/lib/commonUtil';
import i18n from '@/plugins/i18n';

// バリデーション
export const validation = {
  // 必須入力
  required: value => !!value,

  // 半角スペースなし
  noSpace: value => (value || '').indexOf(' ') < 0,

  // 最大文字数
  maxLength: maxLength => value => !value || value.length <= maxLength,

  // 文字数 (電話番号)
  telNumberLength: value =>
    !!value && validation.pattern(value, 'telNumberLength'),

  // ルール (パターン)
  pattern: (value, label) => !!value && validateValue(value, label),

  // 半角数字の存在チェック
  requiredHalfNumber: value => !(value.match(/[0-9]+/) == null),

  // 半角大文字の存在チェック
  requiredHalfUppercase: value => !(value.match(/[A-Z]+/) == null),

  // 半角小文字の存在チェック
  requiredHalfLowercase: value => !(value.match(/[a-z]+/) == null),

  // 非同一性チェック
  notConfirmed: (value, target) => !(value == target),

  // 同一性チェック
  confirmed: (value, target) => !(value != target),

  // 必須タグ:{fullNameKanji}
  requiredFullNameKanji: value =>
    !value || (value.match(/{fullNameKanji}/g) || []).length >= 1,

  // 必須タグ:{insuranceName}
  requiredInsuranceName: value =>
    !value || (value.match(/{insuranceName}/g) || []).length >= 1,

  // 必須タグ:{startDate}
  requiredStartDate: value =>
    !value || (value.match(/{startDate}/g) || []).length >= 1,

  // 必須タグ:{lastAccessibleDay}
  requiredLastAccessibleDay: value =>
    !value || (value.match(/{lastAccessibleDay}/g) || []).length >= 1,

  // 必須タグ:{contractUrl}
  requiredContractUrl: value =>
    !value || (value.match(/{contractUrl}/g) || []).length >= 1,

  // 必須タグ:{agencyName}
  requiredAgencyName: value =>
    !value || (value.match(/{agencyName}/g) || []).length >= 1,

  // 必須タグ:{chargePersonName}
  requiredChargePersonName: value =>
    !value || (value.match(/{chargePersonName}/g) || []).length >= 1,

  // 必須タグ:{agencyPhoneNumber}
  requiredAgencyPhoneNumber: value =>
    !value || (value.match(/{agencyPhoneNumber}/g) || []).length >= 1,

  // 必須タグ:{officialWebSiteUrl}
  requiredOfficialWebSiteUrl: value =>
    !value || (value.match(/{officialWebSiteUrl}/g) || []).length >= 1,

  // 必須タグ:{officialLineUrl}
  requiredOfficialLineUrl: value =>
    !value || (value.match(/{officialLineUrl}/g) || []).length >= 1,

  // 必須タグ:{modificationContent}
  requiredModificationContent: value =>
    !value || (value.match(/{modificationContent}/g) || []).length >= 1,

  // 使用不可タグ:{modificationContent}
  unusableModificationContent: value => !value.match(/{modificationContent}/g),
};

// ルール (必須入力)
const requiredRule = value =>
  validation.required(value) || i18n.t('validation.required');

// ルール (半角スペースなし)
const noSpaceRule = value =>
  validation.noSpace(value) || i18n.t('validation.noSpace');

// ルール (最大文字数)
const maxLengthRule = max =>
  validation.maxLength(max) || i18n.t('validation.max', { length: max });

// ルール (文字数（電話番号）)
const telNumberLengthRule = value => validation.telNumberLength(value);

// ルール (パターン)
const patternRule = pattern => value => validation.pattern(value, pattern);

// バリデーション (電話番号)
export const telRules = [noSpaceRule, requiredRule, telNumberLengthRule];

// バリデーション (メールアドレス)
export const mailRules = [
  noSpaceRule,
  patternRule('mailAddress'),
  maxLengthRule(MaxLength.MailAddress),
];

// バリデーション (代理店コード)
export const agencyCodeRules = [noSpaceRule, patternRule('agencyCode')];

export class Length {
  // 最大文字数より長いかどうか
  static isLongerThanMax = (value, maxLength = MaxLength.Default) => {
    return !value || value.length > maxLength;
  };
}
