<template>
  <ValidationObserver ref="observer">
    <v-form @submit.prevent> <slot></slot> </v-form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

export default {
  name: 'ValidationForms',
  components: { ValidationObserver },
  methods: {
    async validate() {
      return await this.$refs.observer.validate();
    },
    async reset() {
      return await this.$refs.observer.reset();
    },
  },
};
</script>
