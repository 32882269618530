<template>
  <ValidationProvider :rules="rules" v-slot="{ errors }" style="width: 100%;">
    <slot></slot>

    <ValidationError class="ma-0" :error="errors[0]" />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import ValidationError from '@/components/organisms/common/ValidationError';
import '@/lib/veeValidation';

export default {
  name: 'ValidationForms',
  components: {
    ValidationProvider,
    ValidationError,
  },
  props: {
    rules: String,
  },
};
</script>
