<template>
  <p class="mt-2" style="font-size: 12px; color: #f55a4e;">
    {{ error }}
  </p>
</template>

<script>
export default {
  name: 'ValidationError',
  props: {
    error: String,
  },
};
</script>
